import { t } from "i18next";
import { useFormContext } from "react-hook-form";
import { ErrorBy, FormError, GlobalError } from "../../organisms/form";
import {
  Label,
  SelectDropdown,
  PrimaryButton,
  SecondaryButton,
} from "@sentaraui/optimahealth_web";
import { useAuth } from "providers";
import { useNavigate } from "react-router";
import { UNPAID_GROUP_BALANCE } from "utils/constants/Routes";

export const UnpaidGroupBalanceForm = ({
  showUnLocate,
}: {
  showUnLocate: boolean;
}) => {
  const { register, getValues, trigger, formState, reset } = useFormContext();
  const { user } = useAuth();
  const navigate = useNavigate();

  const clearFormFields = () => {
    reset({
      groupNumber: "",
      overduePeriod: "",
    });
    navigate(`/app/${UNPAID_GROUP_BALANCE}`);
  };

  const searchByValidate = () => {
    if (!getValues("groupNumber") && !getValues("overduePeriod")) {
      return t("unpaidGroupBalance.validation.eitherOneSelected");
    }
  };

  register("searchInput", { validate: searchByValidate });
  return (
    <>
      <ErrorBy
        isShow={showUnLocate && formState.isSubmitted}
        message={t("unpaidErrorMsg")}
      />
      <div className="row">
        <div className="col-lg-7 col-md-8 col-sm-12 col-12">
          <GlobalError name="searchInput" />
          <div className="mb-4">
            <Label
              className="pb-1"
              dataTestId="lblGroupNumber"
              htmlFor="unpaidGroupNumber"
            >
              {t("viewEligibilityForm.groupNameLabel")}
            </Label>
            <SelectDropdown
              data-testid="optGroupNumber"
              {...register("groupNumber", {
                onChange: () => {
                  trigger("searchInput");
                },
              })}
              className={
                formState.errors.dropdownOption?.message
                  ? " form-select error-input-bdr"
                  : "form-select"
              }
              id="unpaidGroupNumber"
            >
              <option value="" disabled>
                {t("viewEligibilityForm.selectGroup")}
              </option>
              {user?.groups?.map((item, key) => {
                return (
                  <option
                    data-testid="optioncheck"
                    key={`${item.groupID}-${key}`}
                    value={item.groupID}
                  >
                    {item.groupID} {item.groupName}
                  </option>
                );
              })}
            </SelectDropdown>
            <p
              className="granite-gray-color mt-2"
              data-testid="txtGroupNumberDescription"
            >
              <small>{t("unpaidGroupBalance.label.numberSpecificGroup")}</small>
            </p>
            <FormError name="groupNumber" />
          </div>

          <div className="py-2">
            <div className="pos-rel">
              <hr className="m-0" />
              <strong className="or-separator">OR</strong>
            </div>
          </div>
          <div className="mb-4">
            <Label
              className="pb-1"
              dataTestId="lblAllGroupsByOverdueAccountDays"
              htmlFor="overduePeriodDays"
            >
              {t("unpaidGroupBalance.label.overduePeriod")}
            </Label>
            <SelectDropdown
              className="form-select"
              dataTestId="optOverDueDebitPeriod"
              id="overduePeriodDays"
              {...register("overduePeriod", {
                onChange: () => {
                  trigger("searchInput");
                },
              })}
            >
              <option value="">
                {t("unpaidGroupBalance.placeholder.overduePeriod")}
              </option>
              {[15, 30, 60, 90].map((number) => {
                return (
                  <option key={number} value={number}>
                    {t("unpaidGroupBalance.label.numberofdays", {
                      number: number,
                    })}
                  </option>
                );
              })}
            </SelectDropdown>
            <FormError name="overduePeriod" />
          </div>
        </div>
        <hr className="mt-0 mb-4" />
        <div className="d-flex flex-column flex-lg-row flex-md-row gap-3 mb-5">
          <PrimaryButton
            data-testid="btnSearch"
            id="primary"
            type="submit"
            className={
              Object.keys(formState.dirtyFields).length > 0
                ? "primary_btn btn_block_mob"
                : "primary_btn disabled_btn btn_block_mob"
            }
          >
            {t("button.label.search")}
          </PrimaryButton>
          <SecondaryButton
            data-testid="btnClear"
            id="clear_btn"
            type="button"
            onClick={clearFormFields}
            className="secondary_btn"
          >
            {t("button.label.clear")}
          </SecondaryButton>
        </div>
      </div>
    </>
  );
};

export default UnpaidGroupBalanceForm;
