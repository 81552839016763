import { LeftNavigations } from "utils/constants/Navigation"
import MenuLinks from "./MenuLinks"
import { useTranslation } from "react-i18next";
import {
    Accordion,
    QuoteEnrollManageIcon,
    CustomerSupportIcon,
    SettingsIcon,
    MoneyIcon,
    BookIcon,
    CommissionIcon,
} from '@sentaraui/optimahealth_web';
import { useAuth, useGlobalState, useBranding } from "providers";
import { LinkListType } from "utils/model/CommonTypes";
import { useState } from "react";
import { userTypeLob } from "utils/helpers/HelperFn";
import { USERTYPE } from "utils/model/CommonTypes";

type Props = {
    setIsOpen?: (v: boolean) => void,
    id: string
}

export const MenuAccordion = (props: Props) => {
    const [initialActiveIndex, setInitialActiveIndex] = useState(0)
    const { setIsOpen = () => { } } = props
    const { t } = useTranslation();
    const { isAccess, user } = useAuth()
    const { refreshKey } = useGlobalState();
    const userType = userTypeLob(user);
    const { brandConfig } = useBranding();
    const handleAccordionToggleItem = (index: number) => {
        return index;
    };
    const MenuIconName = (item: { title: string; }) => {
        if (item.title === 'nav.member') {
            return <CustomerSupportIcon />
        }
        else if (item.title === 'nav.manageYourBookQuoteAndEnroll') {
            return <QuoteEnrollManageIcon />
        }
        else if (item.title === 'nav.bookOfBusiness') {
            return <BookIcon />
        }
        else if (item.title === 'nav.commission') {
            return <CommissionIcon />
        }
        else if (item.title === 'nav.invoiceAndOverdue') {
            return <MoneyIcon />
        }
        else {
            return <SettingsIcon />
        }
    }

    const menuItems: any = []
    LeftNavigations.forEach((item: any, key) => {
        let access = false;
        if(brandConfig.brandName === "AVMED" || userType !== USERTYPE.MEDICARE){
            let actIndex = item.list.findIndex((item: LinkListType) => item.id === 'lnkMedicareActivityEvolveNxt')
            if (actIndex >= 0) {
                item.list.splice(actIndex, 1)
            }
            actIndex = item.list.findIndex((item: LinkListType) => item.id === 'lnkMedicareActivityTableau')
            if (actIndex >= 0) {
                item.list.splice(actIndex, 1)
            }
        }
        if(brandConfig.brandName !== "AVMED" && userType === USERTYPE.MEDICARE){
            let actIndex = item.list.findIndex((item: LinkListType) => item.id === 'lnkCommissionActivity')
            if (actIndex >= 0) {
                item.list.splice(actIndex, 1)
            }
        }
        if (userType === USERTYPE.COMBINED && brandConfig.brandName !== "AVMED") {
            let actIndex = item.list.findIndex((item: LinkListType) => item.id === 'lnkCommissionActivity')
            if (actIndex >= 0) {
                item.list.splice(actIndex, 1)
            }
        } else {
            let actIndex = item.list.findIndex((item: LinkListType) => item.id === 'lnkCommercialCommissionActivity')
            if (actIndex >= 0) {
                item.list.splice(actIndex, 1)
            }
            actIndex = item.list.findIndex((item: LinkListType) => item.id === 'lnkMedicareCommissionActivity')
            if (actIndex >= 0) {
                item.list.splice(actIndex, 1)
            }
        }      
        item.list.forEach((item: LinkListType) => {
            if (isAccess(item.to)) {
                access = true
            }
        })

        if (access) {
            menuItems.push(
                {
                    accIcon: MenuIconName(item),
                    id: 'member_profile',
                    content: (
                        <MenuLinks no={menuItems.length} setInitialActiveIndex={setInitialActiveIndex} setIsOpen={setIsOpen} list={item.list} />
                    ),
                    title: t(item.title),
                }
            )
        }
    })
    return <Accordion
        key={refreshKey}
        onToggleItem={handleAccordionToggleItem}
        initialActiveIndex={initialActiveIndex}
        id={props.id}
        items={menuItems}
    />
}
