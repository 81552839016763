import { t } from "i18next";
import React, { useEffect, useState } from "react";
import SSONavigationContent from "pages/sso/SSONavigationContent";
import { useCommissionActivity } from "@sentara/sentara-api-hooks-core";
import { Brands, useAuth, useBranding } from "providers";
import { avMedCommissionActivityUrl } from 'utils/constants/Navigation';

export const CommissionActivity = () => {
  const { user } = useAuth();
  const { state, getCommissionActivitySSO } = useCommissionActivity();
  const { brandConfig } = useBranding();

  useEffect(() => {
    if (brandConfig.brandName !== "AVMED" && (brandConfig.brandName === "DEFAULT" && !window.location.pathname.includes('medicare-commission-activity'))) {
      fetchSSO();
    }
    document.title = t('pageTitleADA.commissionActivity');
  }, [window.location.pathname]);


  const fetchSSO = async () => {
    if (user?.loginID) {
      await getCommissionActivitySSO({ userID: user.loginID });
    }
  };

  return (
    <SSONavigationContent
      header={t("sso.header.commissionActivity")}
      ssoUrl={(brandConfig.brandName === "AVMED" || (brandConfig.brandName === "DEFAULT" && window.location.pathname.includes('medicare-commission-activity'))) ? avMedCommissionActivityUrl :
        state?.Result?.ssoUrl}
      dataTestId="ssoLinkCommissionsActivity"
      targetName={(window.location.pathname.includes('medicare-commission-activity')) ? "MedicareCommissionActivity" : "CommissionActivity"}
    />
  );
};

export default CommissionActivity;
