import { SelectDropdown, InputField } from "@sentaraui/optimahealth_web";
import { useFormContext } from "react-hook-form";
import { t } from "i18next";
import { FormError, GlobalError } from "../../organisms/form";
import ViewEligibilitySubmit from "./ViewEligibilitySubmit";
import {
    NameRegex,
    NumbersWithCharacters,
    NumericAsterisks,
    PolicyRegex,
    SSNRegex,
    MBIRegex,
    SubscriberIDRegex,
} from "utils/constants/Regex";
import {
    MBILength,
    SSNLength,
    VALIDATION_LENGTH,
    memberNumberLength,
} from "utils/constants/Constants";
import { useAuth } from "providers";
import { NameChange, ssnOncheck } from "../../utils/helpers/HelperFn";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { VIEW_ELIGIBILITY } from "utils/constants/Routes";
import { useSearchParams } from "react-router-dom";
import { USERTYPE } from "utils/model/CommonTypes";
import { userTypeLob } from "utils/helpers/HelperFn";

interface ViewEligibilityFormProps {
    lob: string;
    lobChanged: boolean;
    clearForm: boolean;
    selectCriteriaValidation: Function;
    setHasDirtyFields: Function;
    setSearchError: Function;
  }

function ViewEligibilityForm({lob, lobChanged,clearForm, selectCriteriaValidation, setHasDirtyFields, setSearchError} : ViewEligibilityFormProps ) {
    const { register, reset, resetField, formState, getValues, trigger } =
        useFormContext();
    const { user } = useAuth();
    const userType = userTypeLob(user);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if(lobChanged) {
            handleClearForm()
        }
    },[lob,lobChanged])

    useEffect(() => {
    if(clearForm) {
        handleClearForm()
    }
    },[clearForm])

    //For reseting the form
    const handleClearForm = () => {
        reset({
            groupId: "",
            policyId: "",
            memberId: "",
            subscriberSecurityNumber: "",
            memberBeneficiaryIdentifier:"",
            firstName: "",
            lastName: "",
        });
        setSearchError({})
        selectCriteriaValidation(false)
        if(!(USERTYPE.COMBINED === userType && lob === 'medicare' && Object.keys(formState.dirtyFields).length > 0)) {
            navigate(`/app/${VIEW_ELIGIBILITY}`);
        }        
    };

    const searachByValdate = () => {
        const { groupId, searchInput, lineOfBusiness, ...rest } = getValues();
        const result: any = [];
        Object.keys(rest).forEach((key) => {
            if (key !== "firstName" && rest[key]) {
                result.push(rest[key]);
            }
        });
        if (!result.length) {
            selectCriteriaValidation(true)
            return t("viewEligibilityForm.validation.eitherOneSelected");
        }
        selectCriteriaValidation(false)
    };
    const lastNameValidate = (value: string) => {
        const fistName = getValues("firstName");
        if (fistName && !value) {
            return t("viewEligibilityForm.validation.lastNameMust");
        }
    };

    register("searchInput", { validate: searachByValdate });
    setHasDirtyFields(formState.dirtyFields)

    return (
        <div>
            <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                    {lob !== "medicare" &&
                        <div className="mb-4">
                            <label
                                className="pb-1"
                                htmlFor="selectAGroup"
                                data-testid="lblGroupNumber"
                            >
                                {t("viewEligibilityForm.groupNameLabel")}
                            </label>
                            <div>
                                <SelectDropdown
                                    dataTestId="optSelectAGroup"
                                    {...register("groupId", {
                                        required: t("viewEligibilityForm.selectOption"),
                                    })}
                                    className={
                                        formState.errors.groupId?.message
                                            ? " form-select error-input-bdr"
                                            : "form-select"
                                    }
                                    id="selectAGroup"
                                    aria-describedby="select_group_error"
                                >
                                    <option value="" disabled>
                                        {t("viewEligibilityForm.selectGroup")}
                                    </option>
                                    {user?.groups?.map((item) => {
                                        return (
                                            <option key={`${item.groupID}`} value={item.groupID}>
                                                {item.groupID} {item.groupName}
                                            </option>
                                        );
                                    })}
                                </SelectDropdown>
                                <div
                                    className={formState.errors.groupId?.message ? "" : "d-none"}
                                >
                                    <div
                                        id="select_group_error"
                                        aria-live="polite"
                                        aria-atomic="true"
                                    >
                                        <FormError name="groupId" />
                                    </div>
                                </div>
                            </div>
                        </div>}
                    {lob !== "medicare" && <>
                        <div className="mb-4">
                            <label
                                className="pb-1"
                                htmlFor="viewEligibilityPolicyId"
                                data-testid="lblPolicyId"
                            >
                                {t("viewEligibilityForm.policyIdLabel")}
                            </label>
                            <div>
                                <InputField
                                    pattern={NumbersWithCharacters}
                                    maxLength={VALIDATION_LENGTH.POLICY_ID_MAX_LENGTH}
                                    {...register("policyId", {
                                        pattern: {
                                            value: PolicyRegex,
                                            message: t("viewEligibilityForm.validationPolicyID"),
                                        },
                                        onChange: () => {
                                            trigger("searchInput");
                                            resetField("firstName");
                                            resetField("lastName");
                                            resetField("memberId");
                                            resetField("subscriberSecurityNumber");
                                            resetField("memberBeneficiaryIdentifier");
                                        },
                                    })}
                                    className={
                                        formState.errors.policyId?.message
                                            ? "form-input error-input-bdr"
                                            : "form-input"
                                    }
                                    placeholder={t("viewEligibilityForm.plcHolderPolicyId")}
                                    dataTestId="inputPolicyId"
                                    id="viewEligibilityPolicyId"
                                    aria-describedby="policy_id__error"
                                />
                                <div
                                    className={formState.errors.policyId?.message ? "" : "d-none"}
                                >
                                    <div
                                        id="policy_id__error"
                                        aria-live="polite"
                                        aria-atomic="true"
                                    >
                                        <FormError name="policyId" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pos-rel pt-2">
                            <hr className="m-0" />
                            <strong className="or-separator">
                            {t("viewEligibilityForm.or")}
                            </strong>
                        </div></>}

                    <div className="mb-4">
                        <label
                            className="pb-1"
                            htmlFor="member_id"
                            data-testid="lblMemberId"
                        >
                            {t("viewEligibilityForm.MemberIdLabel")}
                        </label>
                        <div>
                            <InputField
                                maxLength={VALIDATION_LENGTH.MEMBER_ID_MAX_LENGTH}
                                pattern={NumericAsterisks}
                                {...register("memberId", {
                                    minLength: {
                                        value: memberNumberLength,
                                        message: t("viewEligibilityForm.memberNumberLength"),
                                    },
                                    pattern: {
                                        value: SubscriberIDRegex,
                                        message: t("viewEligibilityForm.validateMemberID"),
                                    },
                                    onChange: () => {
                                        trigger("searchInput");
                                        resetField("firstName");
                                        resetField("lastName");
                                        resetField("policyId");
                                        resetField("subscriberSecurityNumber");
                                        resetField("memberBeneficiaryIdentifier");
                                    },
                                })}
                                className={
                                    formState.errors.memberId?.message
                                        ? "form-input error-input-bdr"
                                        : "form-input"
                                }
                                placeholder={t("viewEligibilityForm.plcHolderMemberId")}
                                dataTestId="inputMemberId"
                                id="member_id"
                                aria-describedby="member_id_error"
                            />
                            <div
                                className={formState.errors.memberId?.message ? "" : "d-none"}
                            >
                                <div id="member_id_error" aria-live="polite" aria-atomic="true">
                                    <FormError name="memberId" />
                                </div>
                            </div>
                            <p className="granite-gray-color pt-1">
                                <small>{t("viewEligibilityForm.includeAsterisk")}</small>
                            </p>
                        </div>
                    </div>
                    <div className="pos-rel pt-2">
                        <hr className="m-0" />
                        <strong className="or-separator">
                        {t("viewEligibilityForm.or")}
                        </strong>
                    </div>
                    { lob !== "medicare" && <>
                    <div className="mb-4">
                        <label
                            className="pb-1"
                            htmlFor="ss_number"
                            data-testid="lblSubscriberSSN"
                        >
                            {t("viewEligibilityForm.sssnLabel")}
                        </label>
                        <div>
                            <InputField
                                maxLength={VALIDATION_LENGTH.SSN_MIN_LENGTH}
                                {...register("subscriberSecurityNumber", {
                                    minLength: {
                                        value: SSNLength,
                                        message: t("viewEligibilityForm.SSNLength"),
                                    },
                                    setValueAs: (v) => ssnOncheck(v),
                                    onChange: (e) => {
                                        e.target.value = ssnOncheck(e.target.value);
                                        trigger("searchInput");
                                        resetField("firstName");
                                        resetField("lastName");
                                        resetField("policyId");
                                        resetField("memberId");
                                        resetField("memberBeneficiaryIdentifier");
                                    },
                                    pattern: {
                                        value: SSNRegex,
                                        message: t("viewEligibilityForm.validationSSN"),
                                    },
                                })}
                                forceNumber={true}
                                className={
                                    formState.errors.subscriberSecurityNumber?.message
                                        ? "form-input error-input-bdr"
                                        : "form-input"
                                }
                                placeholder={t("viewEligibilityForm.plcSSSNumber")}
                                dataTestId="inputSSN"
                                id="ss_number"
                                aria-describedby="ss_number_error"
                            />
                            <div
                                className={
                                    formState.errors.subscriberSecurityNumber?.message
                                        ? ""
                                        : "d-none"
                                }
                            >
                                <div id="ss_number_error" aria-live="polite" aria-atomic="true">
                                    <FormError name="subscriberSecurityNumber" />
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div className="pos-rel pt-2">
                        <hr className="m-0" />
                        <strong className="or-separator">
                        {t("viewEligibilityForm.or")}
                        </strong>
                    </div></>}

                    { lob === "medicare" && <>
                    <div className="mb-4">
                        <label
                            className="pb-1"
                            htmlFor="mbi_number"
                            data-testid="lblSubscriberMBI"
                        >
                            {t("viewEligibilityForm.mbiLabel")}
                        </label>
                        <div>
                            <InputField
                                maxLength={VALIDATION_LENGTH.MBI_MAX_LENGTH}
                                {...register("memberBeneficiaryIdentifier", {
                                    minLength: {
                                        value: MBILength,
                                        message: t("viewEligibilityForm.MBINumberLength"),
                                    },

                                    onChange: (e) => {
                                        trigger("searchInput");
                                        resetField("firstName");
                                        resetField("lastName");
                                        resetField("memberId");
                                    },
                                    pattern: {
                                        value: MBIRegex,
                                        message: t("viewEligibilityForm.validationMBI"),
                                    },
                                })}                  
                                className={
                                    formState.errors.memberBeneficiaryIdentifier?.message
                                        ? "form-input error-input-bdr"
                                        : "form-input"
                                }
                                placeholder={t("viewEligibilityForm.plcMBINumber")}
                                dataTestId="inputMBI"
                                id="mbi_number"
                                aria-describedby="mbi_number_error"
                            />
                            <div
                                className={
                                    formState.errors.memberBeneficiaryIdentifier?.message
                                        ? ""
                                        : "d-none"
                                }
                            >
                                <div id="mbi_number_error" aria-live="polite" aria-atomic="true">
                                    <FormError name="memberBeneficiaryIdentifier" />
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div className="pos-rel pt-2">
                        <hr className="m-0" />
                        <strong className="or-separator">
                        {t("viewEligibilityForm.or")}
                        </strong>
                    </div></>}

                    <div className="mb-4">
                        <label
                            className="pb-1"
                            htmlFor="member_last_name"
                            data-testid="lblMemberLastName"
                        >
                            {t("viewEligibilityForm.lastNameLabel")}
                        </label>
                        <div>
                            <InputField
                                pattern={NameRegex}
                                maxLength={VALIDATION_LENGTH.NAME_MAX_LENGTH}
                                type="text"
                                forceChar={true}
                                {...register("lastName", {
                                    validate: lastNameValidate,
                                    onChange: (e) => {
                                        NameChange(e);
                                        trigger("searchInput");
                                        lob === 'medicare' ? reset({
                                            memberId: "",
                                            subscriberSecurityNumber: "",
                                            memberBeneficiaryIdentifier:"",
                                            policyId:"",
                                            groupId: ""
                                        }) : reset({
                                            memberId: "",
                                            subscriberSecurityNumber: "",
                                            memberBeneficiaryIdentifier:"",
                                            policyId:""
                                        });
                                    },
                                })}
                                className={
                                    formState.errors.lastName?.message
                                        ? "form-input error-input-bdr"
                                        : "form-input"
                                }
                                placeholder={t("viewEligibilityForm.plcHolderLastName")}
                                dataTestId="inputMemberLastName"
                                id="member_last_name"
                                aria-describedby="member_last_name_error"
                            />
                            <div
                                className={formState.errors.lastName?.message ? "" : "d-none"}
                            >
                                <div
                                    id="member_last_name_error"
                                    aria-live="polite"
                                    aria-atomic="true"
                                >
                                    <FormError name="lastName" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <label
                            className="pb-1"
                            htmlFor="member_first_name"
                            data-testid="lblMemberFirstName"
                        >
                            {t("viewEligibilityForm.firstNameLabel")}
                        </label>
                        <div>
                            <InputField
                                pattern={NameRegex}
                                maxLength={VALIDATION_LENGTH.NAME_MAX_LENGTH}
                                {...register("firstName", {
                                    onChange: (e) => {
                                        NameChange(e);
                                        trigger("lastName")
                                        resetField("policyId");
                                        resetField("memberId");
                                        resetField("subscriberSecurityNumber");
                                        resetField("memberBeneficiaryIdentifier");
                                    },
                                })}
                                className={
                                    formState.errors.firstName?.message
                                        ? "form-input error-input-bdr"
                                        : "form-input"
                                }
                                placeholder={t("viewEligibilityForm.plcHolderFirstName")}
                                dataTestId="inputMemberFirstName"
                                id="member_first_name"
                                aria-describedby="member_first_name_error"
                            />
                            <div
                                className={formState.errors.firstName?.message ? "" : "d-none"}
                            >
                                <div
                                    id="member_first_name_error"
                                    aria-live="polite"
                                    aria-atomic="true"
                                >
                                    <FormError name="firstName" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="mt-0 mb-4" />
            <ViewEligibilitySubmit
                isFormDirty={
                    ((lob === "medicare" || getValues("groupId")) &&
                        (getValues("lastName") ||
                            getValues("policyId") ||
                                getValues("memberId")) || getValues("memberBeneficiaryIdentifier")) ||
                    !!searchParams.get("groupId") || 
                    (lob !== "medicare" && (getValues("subscriberSecurityNumber") &&
                    getValues("groupId"))) ||
                    (lob === "medicare" && (getValues("memberId") || 
                        getValues("memberBeneficiaryIdentifier") ||
                        getValues("lastName") ||
                        (getValues("firstName") && getValues("lastName"))))
                }
                handleClearForm={handleClearForm}
            />
        </div>
    );
}

export default ViewEligibilityForm;
